import React, {Component} from "react"
import Button from "@material-ui/core/Button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArrowForwardIcon from "@material-ui/icons/Forward"
import ArrowForwardThinIcon from "@material-ui/icons/ArrowForward"
import CommaIcons from '@material-ui/icons/FormatQuote';
import {isValidContentfulImage, isValidSectionField} from "../common/utils/CommonUtils";
import { graphql, Link } from "gatsby"
import "./service-page.scss"
import "./../pages/learning-library.scss"
import BreadCrumbs from "../components/bread-crumbs";
import ArrowNavigationIcon from "@material-ui/icons/NearMe"
import { StaticImage } from "gatsby-plugin-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

class ServicePage extends Component {

    constructor(props) {

        super(props);
        this.state = {
            openModal: false,

            educationalContentItems: [],
            filteredEducationalItems: [],

            loader: {
                options: {
                    position: 'absolute'
                }
            },
            activeFaqIndex: -1

        };

        this.pageData = this.props.data.contentfulServicePage;
    }

    getFirstContentfulNode = (props, key) => {
        return props.data[key].edges[0].node;
    };

    componentDidMount() {
        window.analytics.page("DrugRehab");
    }

    render() {
        const {disclaimer, shortSectionsHeading, processHeading, shortSections, processItems, topSection,
            belowProcessText, letterToLovedOnes, faQsHeading, faqItems, descriptiveSections, cardsHeading, cards,
            articlesHeading, educationArticleLinks} = this.pageData;


        return (
            <Layout location={this.props.location}>

                <SEO title={topSection.title}/>
                {/*<BreadCrumbs currentPage={"Community Payment"} breadCrumbsList={breadCrumbsList}/>*/}
                <div className="get-started-home-wrapper">
                    <div className="get-started-home-inner">
                        <div className="get-started-text-wrap">
                            <img className="get-started-home-pattern"
                                 src={require(
                                     '../assets/images/home-bg-circles.png')}
                                 alt="Pattern"/>
                            {
                                isValidSectionField(topSection, "title") &&
                                <h1 className="blackHeading2">{topSection.title}</h1>
                            }
                            {
                                isValidSectionField(topSection, "subtitle")
                                &&
                                <p className="subTitle"
                                   dangerouslySetInnerHTML={{__html: topSection.subtitle}}></p>
                            }
                            {
                                isValidSectionField(topSection, "description")
                                &&
                                <p className="parah"
                                   dangerouslySetInnerHTML={{__html: topSection.description.description}}></p>
                            }
                            {
                                disclaimer
                                &&
                                <p className="subPara"
                                   dangerouslySetInnerHTML={{__html: disclaimer.disclaimer}}></p>
                            }
                            <Button onClick={() => {

                            }}
                                    href={"/get-started"}
                                    className="blue-arrow-btn" color="primary">
                                Get Started <ArrowForwardIcon/>
                            </Button>
                        </div>
                        <div className="get-started-anim-wrap">
                            {
                                isValidContentfulImage(topSection.imageOrVideo)
                                && (
                                    <img className="get-started-home-hero-img"
                                         src={topSection.imageOrVideo?.file?.url}
                                         alt="providerImg"/>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="new-home-features">
                    <div className="rehab-process rehab-process-services">
                        {
                            !!cardsHeading && <h2 className="interested-heading mb50">{cardsHeading}</h2>
                        }
                        <div className="rehabProcessList">
                            {cards && cards.map((item, index)=>{
                                return (
                                    <div className="rehabProcessBox" key={item.name+index}>
                                        {isValidContentfulImage(
                                            item.image) && (
                                            <img
                                                className="new-feature-image"
                                                src={item.image.file.url}
                                                alt=" Frame"/>
                                        )}
                                        <div className="processName">{item.name}</div>
                                        <div className="processDescription">{item.description}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="serviceInfoWrapper">
                        {
                            descriptiveSections && descriptiveSections.map((descriptiveSection,index)=>{
                                return <div className="serviceInfoText">
                                    <h2 key={'desc-section-'+index} className="interested-heading">{descriptiveSection.title}</h2>
                                    {
                                        descriptiveSection.description && descriptiveSection.description.description && (
                                            <p className="parah3"
                                               dangerouslySetInnerHTML={{__html: descriptiveSection.description.description.replace(/\n/g, '<br/>')}}/>
                                        )
                                    }

                                </div>
                            })
                        }
                    </div>
                    <div className="articleWrapper">
                        {
                            !!articlesHeading && <h2 className="interested-heading">{articlesHeading}</h2>
                        }
                        <div className="articleLinksWrapper">
                            {
                                educationArticleLinks && educationArticleLinks.map((articleLink, index) =>{
                                    return (
                                        <div className="articleLinks" key={'articleLink'+index}>
                                            <a href={articleLink.linkAddress.linkAddress}>{articleLink.linkTitle}</a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="serviceFeatureWrapper">
                        {
                            shortSectionsHeading && <h2 className="interested-heading">{shortSectionsHeading.shortSectionsHeading}</h2>
                        }
                    <div className="new-home-features-inner">
                        {
                            shortSections && shortSections.map(
                                (section, index) => (
                                    <div key={"key-" + section.heading}
                                         className="new-home-single-feature">
                                        {
                                            index % 2 !== 0 && (
                                                <div
                                                    className="new-feature-img-side">
                                                    <img
                                                        className="new-feature-image"
                                                        src={section.image.file.url}
                                                        alt=" Frame"/>
                                                </div>
                                            )
                                        }
                                        <div className="new-feature-text-side">
                                            {
                                                isValidSectionField(section,
                                                    "heading") &&

                                                <h2 className="feature-heading">
                                                    {section.heading}
                                                </h2>
                                            }

                                            {

                                                isValidSectionField(section,
                                                    "description") &&

                                                <p className="feature-para"
                                                   dangerouslySetInnerHTML={{__html: section.description.description}}></p>
                                            }
                                        </div>
                                        {
                                            (index === 0 || index % 2 === 0) && (
                                                <div
                                                    className="new-feature-img-side"
                                                    style={{textAlign: "right"}}>
                                                    {isValidContentfulImage(
                                                        section.image) && (
                                                        <img
                                                            className="new-feature-image"
                                                            src={section.image.file.url}
                                                            alt=" Frame"/>
                                                    )}

                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                        }
                        <div className="rehab-process">
                            {
                                processHeading && <h2 className="interested-heading mb100">{processHeading}</h2>
                            }
                            <div className="rehabProcessList">
                                {processItems && processItems.map((item, index)=>{
                                    return (
                                        <div className="rehabProcessBox" key={item.name+index}>
                                            <span className="processNumber">{index+1}</span>
                                            {isValidContentfulImage(
                                                item.image) && (
                                                <img
                                                    className="new-feature-image"
                                                    src={item.image.file.url}
                                                    alt=" Frame"/>
                                            )}
                                            <div className="processName">{item.name}</div>
                                            <div className="processDescription">{item.description}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        {
                            belowProcessText && (
                                <div>
                                    <div className="something-main-wrapper">
                                        <div className="something-inner">
                                            <h2 className="something-head">{belowProcessText.belowProcessText}</h2>
                                            <Button className="blue-arrow-btn" href="https://apps.apple.com/us/app/confidant-health/id1478727129">
                                                DOWNLOAD OUR APP <ArrowNavigationIcon/>
                                            </Button>

                                        </div>

                                    </div>
                                </div>
                            )
                        }

                        {
                            letterToLovedOnes && (
                                    <div className="new-home-letter-one">
                                        <div className="new-home-letter-one-inner">
                                            {isValidContentfulImage(
                                                letterToLovedOnes.image) && (
                                                <img
                                                    className="new-feature-image"
                                                    src={letterToLovedOnes.image.file.url}
                                                    alt=" Frame"/>
                                            )}
                                            <h2 className="home-letter-one-heading">{letterToLovedOnes.heading}</h2>
                                            <p className="home-letter-one-para">{letterToLovedOnes.description.description}</p>
                                            <Button href="/letter-to-loved-ones/"
                                                    className="new-blue-link" color="primary">
                                                Read the Letter <ArrowForwardThinIcon/>
                                            </Button>
                                        </div>
                                    </div>
                                )
                        }
                        <div className="faqWrapper">
                            {
                                faQsHeading!==null && <h2 className="interested-heading">{faQsHeading}</h2>
                            }
                            <div className="faqList">
                                {
                                    faqItems && faqItems.map((faqItem, index)=>{
                                        const active = index === this.state.activeFaqIndex;
                                        return (
                                            <div className="faqItems" key={'faq-item-'+ index}>
                                                <Button
                                                    onClick={()=>{
                                                        this.setState({
                                                            activeFaqIndex: index
                                                        })
                                                    }}

                                                        className={active?"new-blue-link-active":"new-blue-link"} color="primary">
                                                    {faqItem.title.title}
                                                </Button>
                                                {
                                                    active && <div>
                                                        {documentToReactComponents(JSON.parse(faqItem.description.raw))}
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </Layout>
        )
    }
}

export default ServicePage;

export const query = graphql`
query getServicePage($slug:String) {
   contentfulServicePage(slug: {eq: $slug}) {

        disclaimer {
          disclaimer
        }
        shortSectionsHeading {
        shortSectionsHeading
      }
      shortSections {
        heading
        image {
          file {
            url
          }
        }
        description {
          description
        }
      }
        processHeading
        processItems {
          image {
            file {
              url
            }
          }
          description
          name
        }
        belowProcessText {
          belowProcessText
        }
        letterToLovedOnes {
          description {
            description
          }
          heading
          image {
            title
            description
            file {
              url
            }
          }
        }
        faQsHeading
      faqItems {
        title {
            title
        }
        description {
            raw
        }
      }
        topSection {
          title
          description {
            description
          }
          imageOrVideo {
            title
            description
            file {
              url
            }
          }
          subtitle
        }
        descriptiveSections {
        title
        description {
          description
        }
      }
      cardsHeading
      cards {
        name
        image {
          file {
            url
          }
        }
        description
      }
      articlesHeading
      educationArticleLinks {
        linkTitle
        linkAddress {
          linkAddress
        }
      }
      }
}


`;
